<template>
  <div class="h-screen flex w-full bg-img no-gutter items-center justify-center" @keyup.enter="registerUser">
    <div class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 sm:m-0 m-4">
      <vx-card class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <div class="vx-row no-gutter">
            <div class="w-full">
              <div class="p-8">
                <div class="vx-card__title mb-6">
                  <h4 class="mb-4 text-center">Register an Account</h4>
                  <p class="text-center">Fill the below form to create a new account</p>
                </div>
                <!-- <vs-card class="mr-0 modal-text bg-rag-green bg-opacity-30">
                  <div class="flex flex-col items-center justify-center p-3 sm:p-3 lg:p-6 xl:p-4">
                    <p class="text-base text-center text-dark-grey font-semibold">Get two free Job Post Credits when you sign up now 🎁!</p>
                  </div>
                </vs-card> -->
                <div class="clearfix">
                  <vs-input
                    v-model="email"
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    name="email"
                    type="email"
                    label-placeholder="Email"
                    placeholder="Email"
                    class="w-full mt-8"
                  />
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                  <vs-input
                    ref="password"
                    v-model="password"
                    v-validate="'required|min:6|max:20'"
                    type="password"
                    data-vv-validate-on="blur"
                    name="password"
                    label-placeholder="Password"
                    placeholder="Password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <vs-input
                    v-model="confirm_password"
                    v-validate="'min:6|max:20|confirmed:password'"
                    type="password"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    name="confirm_password"
                    label-placeholder="Confirm Password"
                    placeholder="Confirm Password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
                  <div class="mt-6 flex items-center">
                    <vs-checkbox v-model="isTermsConditionAccepted" class="flex items-center"></vs-checkbox>
                    <p>
                      <span>I accept the</span>
                      <span><a href="https://www.recruitaguide.co.za/terms-conditions#employer" target="_blank">&nbsp;T&Cs</a></span>
                      <span class="mx-1">and</span>
                      <span><a href="https://www.recruitaguide.co.za/privacy-policy" target="_blank">privacy policy</a></span>
                    </p>
                  </div>
                  <vs-button class="mt-6 w-full" :disabled="!validateForm" @click="registerUser">Register</vs-button>
                  <p class="text-sm mt-3 text-center"
                    >Already have an account?
                    <span class="text-rag-green-dark cursor-pointer hover:underline" @click="toLogin">Sign in</span></p
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showVerifyPopup: false,
      name: '',
      surname: '',
      telephone: '',
      email: '',
      password: '',
      phoneNumber: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
      addToSenderListAccepted: true,
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email != '' &&
        this.password != '' &&
        this.confirm_password != '' &&
        this.isTermsConditionAccepted === true
      );
    },
  },
  methods: {
    toLogin() {
      this.$router.push('/pages/login');
    },
    async registerUser() {
      const isValid = await this.$validator.validate();
      if (!this.validateForm || !isValid) return;
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLoggedIn();
        return;
      }
      this.$vs.loading({ type: 'corners' });
      const payload = {
        userDetails: {
          email: this.email,
          password: this.password,
        },
        notify: this.$vs.notify,
      };
      await this.$store.dispatch('auth/registerUser', payload);
      this.$vs.loading.close();
    },
    notifyAlreadyLoggedIn() {
      this.$vs.notify({
        title: 'Oops!',
        text: 'You are already logged in',
        color: 'warning',
      });
    },
  },
};
</script>
